/* eslint-disable prefer-rest-params, prefer-template */

import { useEffect } from 'react';

const useClarity = () => {
  const projectId = process.env.REACT_APP_CLARITY_PROJECT_ID;

  useEffect(() => {
    if (projectId) {
      try {
        (function (c, l, a, r, i, t, y) {
          c[a] =
            c[a] ||
            function () {
              (c[a].q = c[a].q || []).push(arguments);
            };
          t = l.createElement(r);
          t.async = 1;
          t.src = 'https://www.clarity.ms/tag/' + i;
          y = l.getElementsByTagName(r)[0];
          y.parentNode.insertBefore(t, y);
        })(window, document, 'clarity', 'script', projectId);
      } catch (error) {
        console.log(error);
      }
    }
  }, [projectId]);
};

export default useClarity;
